.bar {
  width: 100%;
  min-height: 80px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.loginSection {
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex: 1;
}

.companySection {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  margin-left: 10px;
  cursor: pointer;
  text-transform: uppercase;
}
